<template>
  <step-wrapper
    title="Оценка качества и безопасности медицинской деятельности"
    class="consolidated-report-step2"
  >
    <div class="ml-4 mt-5">
      Оценка медицинской организации и ее подразделений была проведена путем
      проведения
      {{ dataSource.AuditOperationsContent.PlannedAuditCount }} плановых и
      {{ dataSource.AuditOperationsContent.UnplannedAuditCount }} целевых
      (внеплановых) проверок
    </div>
    <div class="ml-4 mt-12 consolidated-report-step2__grade">
      <div class="title mb-2">
        1. Общая оценка состояния качества и безопасности медицинской
        деятельности по проведенным проверкам
      </div>
      <div
        class="consolidated-report-step2__grade-wrapper"
        v-if="dataSource.AuditOperationsContent"
      >
        <div
          class="consolidated-report-step2__grade-value"
          :class="
            StatisticIndicatorHelper.getColorByRefs(
              dataSource.AuditOperationsContent.RefValues,
              dataSource.AuditOperationsContent.PercentValue,
              true
            ) + '--text'
          "
        >
          {{ dataSource.AuditOperationsContent.PercentValue + "%" }}
        </div>
        <div class="consolidated-report-step2__grade-label">
          {{ dataSource.AuditOperationsContent.Description }}
        </div>
      </div>
    </div>

    <base-list-view
      v-if="
        false &&
        dataSource.AuditOperationsContent &&
        dataSource.AuditOperationsContent.AuditOperations
      "
      title="2. Плановые и внеплановые проверки"
      :dataSource="dataSource.AuditOperationsContent.AuditOperations"
      :headers="headers1"
      hide-default-footer
      label-text-btn-create="Добавить проверкку"
      pageEditName="AuditOperationEdit"
      :showSettings="false"
      notShowSelect
      @customAdd="customAdd"
      :notShowActions="['add', 'delete', 'refresh']"
      @onRefresh="actual"
      style="margin-bottom: 20px !important"
      :item-class="
        (item) => {
          return !item.IncludeReport ? 'disabled' : '';
        }
      "
    >
      <template slot="item.active" slot-scope="scope">
        <div style="width: 150px">
          <v-checkbox v-model="scope.item.IncludeReport" @click.stop />
        </div> </template
    ></base-list-view>

    <base-tree-list-view
      v-if="
        dataSource.AuditOperationsContent &&
        dataSource.AuditOperationsContent.Indicators
      "
      class="mt-12 headers2"
      title="2. Показатели оценки качества и проверки"
      :dataSource="dataSource.AuditOperationsContent.Indicators"
      :headers="headers2"
      hide-default-footer
      label-text-btn-create="Добавить ПОК"
      :showSettings="false"
      notShowSelect
      options="AuditOperations"
      pageEditName="AuditOperationEdit"
      :notShowActions="['delete', 'add', 'refresh', 'search']"
      @onRefresh="actual"
      :funcHandleClickId="
        (val) => {
          return val.AuditOperations ? -val.Id : val.Id;
        }
      "
      :item-class="
        (item) => {
          return !item.IncludeReport ? 'disabled' : '';
        }
      "
      not-filter
    >
      <template slot="item.PercentValue" slot-scope="scope">
        <span
          :class="
            (scope.item.IncludeReport
              ? getColorByRefs(scope.item.RefValues, scope.item.PercentValue)
              : '') + '--text'
          "
          >{{ scope.item.PercentValue }}%</span
        >
      </template>
      <template slot="item.IndicatorName" slot-scope="scope">
        <div :class="{ 'font-weight-bold': scope.item.IndicatorName }">
          {{ scope.item.IndicatorName || scope.item.Subject }}
        </div>
      </template>
      <template slot="item.active" slot-scope="scope">
        <div style="width: 182px">
          <v-checkbox
            v-model="scope.item.IncludeReport"
            @change="setIncludeReport($event, scope.item)"
            @click.stop
          />
        </div>
      </template>
    </base-tree-list-view>

    <div
      v-if="false && dataSource.AuditOperationsContent"
      class="consolidated-report-step2__audit-col mb-6"
    >
      <div class="text">
        <span>Проведено плановых проверок, за период </span>
        <span>{{ dataSource.AuditOperationsContent.PlannedAuditCount }}</span>
      </div>
      <div class="text">
        <span>Проведено целевых (внеплановых) проверок </span>
        <span>{{ dataSource.AuditOperationsContent.UnplannedAuditCount }}</span>
      </div>
    </div>

    <task-list
      v-if="
        dataSource.AuditOperationsContent &&
        dataSource.AuditOperationsContent.Tasks
      "
      :Tasks="dataSource.AuditOperationsContent.Tasks"
      :readOnly="true"
      :markCompleted="false"
      :notShowSelect="false"
      :headerHidden="false"
      isSummaryReport
      @customAdd="customAddTask"
      title="3. Несоотвествия и мероприятия"
      labelTextBtnCreate="Добавить мероприятие"
      :notShowActions="['refresh']"
      style="margin-bottom: 0 !important"
    ></task-list>

    <div
      class="consolidated-report-step2__audit-col ml-4"
      v-if="dataSource.AuditOperationsContent"
    >
      <div class="text consolidated-report-step2__audit-total">
        <div>Всего выявлено несоответствий -</div>
        <div>{{ dataSource.AuditOperationsContent.ViolationCount }}</div>
      </div>
      <div class="text consolidated-report-step2__audit-total">
        <div>Всего разработано корректирующих мероприятий -</div>
        <div>{{ dataSource.AuditOperationsContent.Tasks.length }}</div>
      </div>
      <div class="text consolidated-report-step2__audit-total">
        <div>Выполнено корректирующих мероприятий -</div>
        <div>
          {{
            dataSource.AuditOperationsContent.Tasks.filter((e) => e.Done).length
          }}
        </div>
      </div>

      <div class="text align-center" v-if="false">
        Причины не устраненных несоответствий
        <div>
          <v-checkbox
            v-if="dataSource.AuditOperationsContent"
            v-model="dataSource.AuditOperationsContent.IncludeViolationReasons"
            hide-details
            label="Отражать в отчете"
            class="mt-0"
          />
        </div>
      </div>
      <div class="text mt-2" style="width: 100%; display: block" v-if="false">
        <div v-for="(item, i) in reasons" :key="i">
          {{ i + 1 }}. {{ item.Reason }}
        </div>
      </div>
    </div>

    <div class="mt-12 ml-4">
      <div class="title">4. Заключение</div>
      <v-textarea
        v-if="dataSource.AuditOperationsContent"
        :value="dataSource.AuditOperationsContent.Conclusion"
        @change="dataSource.AuditOperationsContent.Conclusion = $event"
        placeholder="Заключение..."
      ></v-textarea>
    </div>
  </step-wrapper>
</template>
<script>
import Vue from "vue";
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import stepWrapper from "../stepWrapper.vue";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";
import { defaultSummaryReport } from "@/data/defaultObjects";
import { AuditOperationKinds } from "@/data/enums";
import SummaryReportService from "@/services/SummaryReportService";
import BaseListView from "@/layouts/BaseListView.vue";
import BaseTreeListView from "@/layouts/BaseTreeListView.vue";
import TaskList from "@/components/task/TaskList";
import DataHelper from "../../../../../utils/DataHelper";
import StatisticIndicatorHelper from "../../../../statisticIndicator/StatisticIndicatorHelper";

export default {
  name: "consolidated-report-step2",
  components: { BaseListView, BaseTreeListView, stepWrapper, TaskList },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  inject: ["setDefaultData"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiService: SummaryReportService.step2,
      getDefaultObject: defaultSummaryReport.step2(),
      valid: false,
      rules: {
        required: (value) => (!!value && !!value.length) || "Укажите значение.",
      },
      headers1: [
        { text: "Номер", value: "Num" },
        { text: "Предмет проверки", value: "Subject" },
        {
          text: "Период",
          value: "Period",
          dataType: "Period",
          width: "15%",
          align: "center",
          displayText: (e) => Vue.filter("PeriodFormat")(e?.DateIn, e?.DateOut),
        },
        {
          text: "Тип",
          value: "Kind",
          dataType: "enum",
          options: AuditOperationKinds,
        },
        {
          text: "Результат",
          value: "ResultPercent",
          displayText: (e) => e + "%",
        },
        {
          text: "План мероприятий",
          value: "InProject",
          dataType: "enum",
          options: [
            { id: false, value: "Создан" },
            { id: true, value: "Нет" },
          ],
        },
        {
          text: "Включить в отчет",
          value: "active",
          width: "1%",
        },
      ],
      headers2: [
        {
          text: "Показатель/Предмет проверки",
          value: "IndicatorName",
          cellClass: "td-maximum",
        },
        { text: "Номер", value: "Num", align: "center", cellClass: "td4" },
        {
          text: "Период",
          value: "Period",
          dataType: "Period",
          width: "15%",
          align: "center",
          displayText: (e) => Vue.filter("PeriodFormat")(e?.DateIn, e?.DateOut),
        },
        {
          text: "Тип",
          value: "Kind",
          dataType: "enum",
          options: AuditOperationKinds,
          cellClass: "td3",
        },
        {
          text: "Оценка соответствия",
          value: "PercentValue",
          width: "1%",
          cellClass: "td2",
          align: "center",
        },
        {
          text: "Включить в отчет",
          value: "active",
          width: "1%",
        },
      ],
      loading: false,
      dataSource: defaultSummaryReport.step2(),
      reasons: [],
    };
  },
  computed: {
    StatisticIndicatorHelper() {
      return StatisticIndicatorHelper;
    },
    dates: {
      get() {
        let result = [];
        if (this.dataSource) {
          const start = this.dataSource.DateIn;
          const end = this.dataSource.DateOut;
          result = [start, end];
        }
        return result.filter((e) => e);
      },
      set(value) {
        if (!value || value.length === 0) {
          this.dataSource.DateIn = null;
          this.dataSource.DateOut = null;
        } else if (value?.length === 1) {
          this.dataSource.DateIn = value[0];
          this.dataSource.DateOut = null;
        } else if (value?.length === 2) {
          this.dataSource.DateIn = value[0];
          this.dataSource.DateOut = value[1];
        }
      },
    },
  },
  watch: {
    isUnsaved(val) {
      this.$emit("update:isUnsaved", val);
    },
    loading: {
      immediate: true,
      handler(val) {
        this.$emit("update:loading", val);
      },
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.baseInit();
      this.setDefaultData(this.dataSource);
    },
    setIncludeReport(value, item) {
      item.IncludeReport = value;
      if (item.AuditOperations) {
        for (const audit of item.AuditOperations) {
          audit.IncludeReport = value;
        }
      }
    },
    getColorByRefs(
      refs,
      value,
      colors = ["lighten-1 error", "warning", "success", "success"]
    ) {
      if (!refs) return "";
      const refsCount = refs.filter((r) => r || r === 0).length;
      if (!refsCount) return "";

      if (refsCount === 2) colors.pop();
      else if (refsCount === 1) colors = ["lighten-1 error", "success"];

      if (value <= refs[0]) return colors[0];

      switch (refsCount) {
        case 1:
          return colors[1];
        case 2:
          if (value > refs[0] && value <= refs[1]) return colors[1];
          else if (value > refs[1]) return colors[2];
          break;
        case 3:
          if (value > refs[0] && value <= refs[1]) return colors[1];
          if (value > refs[1] && value <= refs[2]) return colors[2];
          if (value > refs[2]) return colors[3];
      }
    },
    actual() {
      console.log("actual");
      this.init({
        query: {
          actual: true,
        },
      });
    },
    customAddTask() {
      const _id = -Math.round(Math.random() * 100000);
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "task/TaskEdit",
        params: {
          _objectId: _id,
          _type: "create",
          simple: true,
          title: "Создание задачи",
          persistent: true,
        },
        callback: () => {
          const task = this.$store.getters["frame/GET_DATA"]().object;
          if (!task.Name) return;
          task.Id = _id;
          this.dataSource.AuditOperationsContent.Tasks.push(task);
          this.$store.dispatch("frame/SHOW_DIALOG", { show: false });
          this.save();
        },
      });
    },
    customAdd() {
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "audit/AuditOperations2",
        params: { simple: true, persistent: true },
      });
    },
    DataFromServerNormalize(data) {
      for (const task of data.AuditOperationsContent.Tasks) {
        if (task.QuestionResult) {
          task.AuditOperationNum = task.QuestionResult.Num;
          task.CheckListName = task.QuestionResult.CheckListName;
        }
      }

      this.reasons = [];
      if (data.AuditOperationsContent) {
        DataHelper.treeListTraverse(data.AuditOperationsContent.Tasks, (e) => {
          if (e.Reason) {
            this.reasons.push(e);
          }
        });
      }

      return data;
    },
    async customInplaceEdit(value) {
      if (!value) return;
      setTimeout(() => {
        this.save();
      }, 1);
      return value;
    },
  },
};
</script>
<style lang="scss">
.consolidated-report-step2 {
  &__audit {
    &-total {
      width: 450px !important;
      font-weight: bold !important;
    }
  }
  .text {
    font-size: 14px;
    font-family: Noto Sans;
    font-weight: 500;
    line-height: 158%;
    color: var(--v-text-primary);
  }
  .title {
    color: var(--v-text-primary);
    font-weight: 600;
    line-height: 158%;
    font-size: 18px;
    letter-spacing: -0.01em;
  }
  &__audit-col {
    div.text {
      width: 500px;
      display: flex;
      justify-content: space-between;
    }
  }
  &__grade {
    &-wrapper {
      display: flex;
    }
    &-value {
      color: var(--ff-8761, --v-error-lighten3);
      font-size: 22px;
      font-family: Noto Sans;
      font-weight: 600;
      line-height: 158%;
    }
    &-label {
      margin-left: 25px;
      align-self: center;
    }
  }

  .headers2 {
    .td2 {
      min-width: 219px !important;
    }
    .td3 {
      min-width: 162px !important;
    }
    .td4 {
      min-width: 99px !important;
    }
  }
}
</style>
